function updateFcn(inquiry_id, order_code, date, guests) {
    let splitDate = date.split('.')
    setCookieBbanket("currentStep", 3, {"max-age": 7260000})
    setCookieBbanket("inquiryIdBanket", inquiry_id, {"max-age": 7260000})
    setCookieBbanket("regOrderCode", order_code, {"max-age": 7260000})
    setCookieBbanket("selectedBanketDate", `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`, {"max-age": 7260000})
    setCookieBbanket("selectedBanketGuests", guests, {"max-age": 7260000})
    // Переход к выбору зала
    document.getElementById("inquiry_id").value = inquiry_id
    document.querySelector(".bbanketModal__calendar-date").innerText = `${splitDate[0]} ${months[parseInt(splitDate[1])]} свободно`
    document.querySelector(".bbanketModal__double").style.display = "none"
    document.querySelector(".bbanketModal__calc").style.display = "flex"
    document.querySelector(".modal-overlay").style.display = "none"
    document.querySelector(".bbanketModal").classList.add('register_complete')
    orderCodeBanket = order_code
    document.querySelector('.bbanketModal__calendar-select .guests').innerText = guests
    document.querySelector('.bbanketModal__calendar-nums .guests').innerText = guests
    document.getElementById("guest_count1").value = guests
    // Скрыьт\показать подходящие залы
    // let zalCount = 0
    document.querySelectorAll('.bbanketModal__calc-grid label input').forEach((room) => {
        if (parseInt(room.getAttribute('data-min')) <= parseInt(guests) && parseInt(room.getAttribute('data-max')) >= parseInt(guests)) {
            room.parentNode.removeAttribute("style")
            room.parentNode.parentNode.querySelector(".calculation__zal-closed").removeAttribute("style")
            room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "-1"
        } else {
            room.parentNode.style.display = "none"
            room.parentNode.parentNode.querySelector(".calculation__zal-closed").style.display = "block"
            room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "0"
        }

        // if (zalCount == 0) {
        //     document.getElementById('zal_empty').style.display = "block"
        // } else {
        //     document.getElementById('zal_empty').style.display = "none"
        // }
    })


}
