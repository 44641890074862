// function include(url) {
//   var script = document.createElement('script');
//   script.src = 'https://preview.velgir.com/projects/bbanketwidget/js/bbanketlibs.min.js';
//   document.getElementsByTagName('head')[0].appendChild(script);
// }
var bbanketModal,
    bbanketWidgetCode,
    bbanketWidgetLink,
    prevReserve,
    orangeDate = [],
    zalBlockClick,
    LookRoomInfo,
    selectBtnClick,
    orderCodeBanket,
    metrikaDopInfoFcn
    // restaurantId,
    // restaurantStatus

typeof bbanketMetrikaId === "undefined" ? bbanketMetrikaIdInc = null : bbanketMetrikaIdInc = bbanketMetrikaId
// let bbanketMetrikaId = null
// Текущий адрес АПИ
// let apiURL = 'https://apibanket.velgir.com/api'
let apiURL = 'https://api.bbanket.com/api'
let siteURL = 'https://api.bbanket.com/widget'

let months = ['января','февраля','марта','апреля','мая','июня','июля','августа','сентября','октября','ноября','декабря']

function replaceAlll(string, search, replace) {
    return string.split(search).join(replace);
}

var currentYear = new Date().getFullYear(),
    currentMounth = new Date().getMonth(),
    currentDay = new Date().getDate(),
    currentDate = new Date(currentYear, currentMounth, currentDay);


var bbanketWidget = {
    // идентификатор HTML элемента в который будет размещен виджет
    idBox: 'bbanketWidget',
    // путь до страницы возвращающей виджет
    // url_wiget: 'widget.html',
    // url_wiget: 'https://preview.velgir.com/projects/bbanketwidget/widget.html',
    url_wiget: 'https://api.bbanket.com/widget/widget.html',
    // путь до страницы стилей виджета
    // url_style: 'css/styles.min.css',
    // url_style: 'https://preview.velgir.com/projects/bbanketwidget/css/styles.min.css',
    url_style: 'https://api.bbanket.com/widget/css/styles.min.css',
    // путь до страницы стилей библиотек
    // url_libs: 'css/bbanketlibs.min.css',
    // url_libs: 'https://preview.velgir.com/projects/bbanketwidget/css/bbanketlibs.min.css',
    url_libs: 'https://api.bbanket.com/widget/css/bbanketlibs.min.css',
    // метод инициализации виджета
    init: function(id) {
        // если идентификатор отсутствует, то будем использовать
        // идентификатор HTML элемента для размещения виджета по умолчанию (т.е. "bbanketWidget")
        let viewType = 1
        if (!id) { id = this.idBox; }
        if (document.getElementById(id)) {
            // подключаем стили виджета
            this.addStyle();
            // this.addLibs();
            try {
                // для кросс-доменного запроса создаем один из ниже указанных объектов
                var XHR = ("onload" in new XMLHttpRequest())?XMLHttpRequest:XDomainRequest;
                // создаем экземпляр объекта
                var xhr = new XHR();
                // запрос на другой домен (асинхронный)
                xhr.open('GET', this.url_wiget, true);
                // событие отслеживает, что запрос был успешно завершён
                xhr.onload = function() {
                    // если существует ответ
                    if (this.response) {
                        // добавляем полученный ответ в HTML элемент
                        document.getElementById(id).innerHTML = this.response;

                        // window.addEventListener("load", () => {
                            // датапикер при регистрации
                            registerDatapicker = new AirDatepicker('#registerDatapicker', {
                                inline: true,
                                startDate: new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7)),
                                onSelect: function () {
                                    const pickerEl = document.getElementById("registerDatapicker").parentNode.querySelector(".air-datepicker")
                                    if (pickerEl.style.display === "none") {
                                        pickerEl.style.display = "block"
                                    } else {
                                        pickerEl.style.display = "none"
                                    }
                                    // console.log(pickerEl);
                                },
                                prevHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.625 23.7498C13.8723 23.75 14.1141 23.6768 14.3198 23.5395C14.5255 23.4023 14.6859 23.2071 14.7806 22.9787C14.8753 22.7502 14.9002 22.4988 14.852 22.2563C14.8039 22.0137 14.6848 21.7909 14.51 21.616L8.77749 15.8835C8.54315 15.6491 8.41151 15.3312 8.41151 14.9998C8.41151 14.6683 8.54315 14.3504 8.77749 14.116L14.5 8.38351C14.7344 8.14896 14.866 7.83091 14.8659 7.49932C14.8658 7.16774 14.7339 6.84978 14.4994 6.61539C14.2648 6.38101 13.9468 6.24939 13.6152 6.24951C13.2836 6.24963 12.9656 6.38146 12.7312 6.61602L6.99999 12.3485C6.29698 13.0517 5.90204 14.0054 5.90204 14.9998C5.90204 15.9941 6.29698 16.9478 6.99999 17.651L12.7325 23.3835C12.8496 23.5006 12.9888 23.5933 13.142 23.6562C13.2952 23.7191 13.4594 23.7509 13.625 23.7498Z" fill="#675BE1"/><path d="M22.375 23.75C22.6214 23.7482 22.8618 23.6737 23.066 23.5356C23.2702 23.3976 23.429 23.2023 23.5225 22.9743C23.616 22.7463 23.64 22.4957 23.5915 22.2541C23.543 22.0125 23.4242 21.7906 23.25 21.6163L16.6387 15L23.25 8.38377C23.4657 8.14621 23.5818 7.83481 23.5742 7.51402C23.5666 7.19324 23.436 6.88765 23.2094 6.66051C22.9827 6.43338 22.6774 6.30211 22.3566 6.29387C22.0359 6.28563 21.7242 6.40107 21.4862 6.61627L13.9862 14.1163C13.7519 14.3507 13.6202 14.6686 13.6202 15C13.6202 15.3315 13.7519 15.6494 13.9862 15.8838L21.4862 23.3838C21.6028 23.5004 21.7414 23.5929 21.894 23.6557C22.0465 23.7186 22.21 23.7506 22.375 23.75Z" fill="#675BE1"/></svg>',
                                nextHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.375 23.7498C16.1277 23.75 15.8859 23.6768 15.6802 23.5395C15.4745 23.4023 15.3141 23.2071 15.2194 22.9787C15.1247 22.7502 15.0998 22.4988 15.148 22.2563C15.1961 22.0137 15.3152 21.7909 15.49 21.616L21.2225 15.8835C21.4568 15.6491 21.5885 15.3312 21.5885 14.9998C21.5885 14.6683 21.4568 14.3504 21.2225 14.116L15.5 8.38351C15.2656 8.14896 15.134 7.83091 15.1341 7.49932C15.1342 7.16774 15.2661 6.84978 15.5006 6.61539C15.7352 6.38101 16.0532 6.24939 16.3848 6.24951C16.7164 6.24963 17.0344 6.38146 17.2688 6.61602L23 12.3485C23.703 13.0517 24.098 14.0054 24.098 14.9998C24.098 15.9941 23.703 16.9478 23 17.651L17.2675 23.3835C17.1504 23.5006 17.0112 23.5933 16.858 23.6562C16.7048 23.7191 16.5406 23.7509 16.375 23.7498Z" fill="#675BE1"/><path d="M7.62505 23.75C7.37861 23.7482 7.13819 23.6737 6.93401 23.5356C6.72983 23.3976 6.57101 23.2023 6.4775 22.9743C6.38399 22.7463 6.35998 22.4957 6.40847 22.2541C6.45696 22.0125 6.5758 21.7906 6.75005 21.6163L13.3613 15L6.75005 8.38377C6.53434 8.14621 6.41825 7.83481 6.4258 7.51402C6.43336 7.19324 6.56399 6.88765 6.79064 6.66051C7.01729 6.43338 7.3226 6.30211 7.64337 6.29387C7.96414 6.28563 8.27579 6.40107 8.5138 6.61627L16.0138 14.1163C16.2481 14.3507 16.3798 14.6686 16.3798 15C16.3798 15.3315 16.2481 15.6494 16.0138 15.8838L8.5138 23.3838C8.39716 23.5004 8.25857 23.5929 8.10604 23.6557C7.9535 23.7186 7.79003 23.7506 7.62505 23.75Z" fill="#675BE1"/></svg>'
                            })
                            registerDatapicker.selectDate(new Date(new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7)))
                            // // // //

                            mainDatapicker = new AirDatepicker('#datapicker', {
                                inline: true,
                                navTitles: {
                                    days: 'MMMM yyyy'
                                },
                                onSelect: function ({formattedDate, date}) {
                                    let splitDate = formattedDate.split('.')

                                    let dataAjax = {
                                        room_id: document.getElementsByClassName('bbanketModal__calc-grid')[0].getAttribute('data-room'),
                                        guest_count: document.getElementById('guest_count1').value,
                                        date: `${date.getFullYear()}-${(parseInt(date.getMonth()) + 1) < 10 ? "0" + (parseInt(date.getMonth()) + 1) : parseInt(date.getMonth()) + 1}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`,
                                        order_code: orderCodeBanket,
                                    }

                                    setTimeout(() => {
                                        let dayEl = document.querySelector(".calendar-mobile .air-datepicker-cell.-day-.-selected-"),
                                            dateBlock = document.querySelector('.bbanketModal__calendar-date')
                                        if (dayEl.classList.contains('red')) {
                                            document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} занято`)
                                            dateBlock.classList.add('red')
                                            dateBlock.classList.remove('orange')
                                            document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                            document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                            document.querySelector(".bbanketModal__calendar-option").setAttribute("disabled", "disabled")
                                        } else if (dayEl.classList.contains('particalRed')) {
                                            document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} частично занято`)
                                            dateBlock.classList.add('red')
                                            dateBlock.classList.remove('orange')
                                            document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                            document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                            document.querySelector(".bbanketModal__calendar-option").setAttribute("disabled", "disabled")
                                        } else if (dayEl.classList.contains('otherClient')) {
                                            document.querySelector('.bbanketModal__calendar-date').innerHTML = `${date.getDate()} ${months[parseInt(splitDate[1])-1]} - предварительная бронь`
                                            dateBlock.classList.add('orange')
                                            dateBlock.classList.remove('red')
                                            document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                            document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                            document.getElementById("btn_broned_mob").setAttribute("disabled", "disabled")
                                            document.querySelector(".you_broned_date").innerText = formattedDate
                                            document.querySelector(".you_broned_zal").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                            document.querySelector(".bbanketModal__calendar-option").setAttribute("disabled", "disabled")
                                        } else if (dayEl.classList.contains('thisClient')) {
                                            document.querySelector('.bbanketModal__calendar-date').innerHTML = `${date.getDate()} ${months[parseInt(splitDate[1])-1]} - ваша заявка отправлена`
                                            dateBlock.classList.add('orange')
                                            dateBlock.classList.remove('red')
                                            document.getElementById("btn_broned_mob").setAttribute("disabled", "disabled")
                                            document.querySelector(".you_broned_date").innerText = formattedDate
                                            document.querySelector(".you_broned_zal").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                            document.getElementById("btn_broned_mob").classList.remove("bronedOther")
                                            document.querySelector(".bbanketModal__calendar-option").setAttribute("disabled", "disabled")
                                        } else {
                                            document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} свободно`)
                                            dateBlock.classList.remove('red')
                                            dateBlock.classList.remove('orange')
                                            document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                            document.getElementById("btn_broned_mob").classList.remove("bronedOther")
                                            document.querySelector(".bbanketModal__calendar-option").removeAttribute("disabled")
                                        }
                                    }, 120)

                                    checkDateAjax(dataAjax)

                                },
                                prevHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.625 23.7498C13.8723 23.75 14.1141 23.6768 14.3198 23.5395C14.5255 23.4023 14.6859 23.2071 14.7806 22.9787C14.8753 22.7502 14.9002 22.4988 14.852 22.2563C14.8039 22.0137 14.6848 21.7909 14.51 21.616L8.77749 15.8835C8.54315 15.6491 8.41151 15.3312 8.41151 14.9998C8.41151 14.6683 8.54315 14.3504 8.77749 14.116L14.5 8.38351C14.7344 8.14896 14.866 7.83091 14.8659 7.49932C14.8658 7.16774 14.7339 6.84978 14.4994 6.61539C14.2648 6.38101 13.9468 6.24939 13.6152 6.24951C13.2836 6.24963 12.9656 6.38146 12.7312 6.61602L6.99999 12.3485C6.29698 13.0517 5.90204 14.0054 5.90204 14.9998C5.90204 15.9941 6.29698 16.9478 6.99999 17.651L12.7325 23.3835C12.8496 23.5006 12.9888 23.5933 13.142 23.6562C13.2952 23.7191 13.4594 23.7509 13.625 23.7498Z" fill="#675BE1"/><path d="M22.375 23.75C22.6214 23.7482 22.8618 23.6737 23.066 23.5356C23.2702 23.3976 23.429 23.2023 23.5225 22.9743C23.616 22.7463 23.64 22.4957 23.5915 22.2541C23.543 22.0125 23.4242 21.7906 23.25 21.6163L16.6387 15L23.25 8.38377C23.4657 8.14621 23.5818 7.83481 23.5742 7.51402C23.5666 7.19324 23.436 6.88765 23.2094 6.66051C22.9827 6.43338 22.6774 6.30211 22.3566 6.29387C22.0359 6.28563 21.7242 6.40107 21.4862 6.61627L13.9862 14.1163C13.7519 14.3507 13.6202 14.6686 13.6202 15C13.6202 15.3315 13.7519 15.6494 13.9862 15.8838L21.4862 23.3838C21.6028 23.5004 21.7414 23.5929 21.894 23.6557C22.0465 23.7186 22.21 23.7506 22.375 23.75Z" fill="#675BE1"/></svg>',
                                nextHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.375 23.7498C16.1277 23.75 15.8859 23.6768 15.6802 23.5395C15.4745 23.4023 15.3141 23.2071 15.2194 22.9787C15.1247 22.7502 15.0998 22.4988 15.148 22.2563C15.1961 22.0137 15.3152 21.7909 15.49 21.616L21.2225 15.8835C21.4568 15.6491 21.5885 15.3312 21.5885 14.9998C21.5885 14.6683 21.4568 14.3504 21.2225 14.116L15.5 8.38351C15.2656 8.14896 15.134 7.83091 15.1341 7.49932C15.1342 7.16774 15.2661 6.84978 15.5006 6.61539C15.7352 6.38101 16.0532 6.24939 16.3848 6.24951C16.7164 6.24963 17.0344 6.38146 17.2688 6.61602L23 12.3485C23.703 13.0517 24.098 14.0054 24.098 14.9998C24.098 15.9941 23.703 16.9478 23 17.651L17.2675 23.3835C17.1504 23.5006 17.0112 23.5933 16.858 23.6562C16.7048 23.7191 16.5406 23.7509 16.375 23.7498Z" fill="#675BE1"/><path d="M7.62505 23.75C7.37861 23.7482 7.13819 23.6737 6.93401 23.5356C6.72983 23.3976 6.57101 23.2023 6.4775 22.9743C6.38399 22.7463 6.35998 22.4957 6.40847 22.2541C6.45696 22.0125 6.5758 21.7906 6.75005 21.6163L13.3613 15L6.75005 8.38377C6.53434 8.14621 6.41825 7.83481 6.4258 7.51402C6.43336 7.19324 6.56399 6.88765 6.79064 6.66051C7.01729 6.43338 7.3226 6.30211 7.64337 6.29387C7.96414 6.28563 8.27579 6.40107 8.5138 6.61627L16.0138 14.1163C16.2481 14.3507 16.3798 14.6686 16.3798 15C16.3798 15.3315 16.2481 15.6494 16.0138 15.8838L8.5138 23.3838C8.39716 23.5004 8.25857 23.5929 8.10604 23.6557C7.9535 23.7186 7.79003 23.7506 7.62505 23.75Z" fill="#675BE1"/></svg>'
                            })

                            // console.log('load');

                            let firstForm = document.getElementsByClassName('sendCode')[0]
                            let secondForm = document.getElementsByClassName('bbanketModal__form_second')[0]

                            let timerInput = 30 // Берём строку
                            let timerShow = document.getElementById("currentTimer");
                            let timeMinut

                            // ajax при загрузке
                            let loadReq = new XMLHttpRequest(),
                                reqRest = new XMLHttpRequest(),
                                reqRooms = new XMLHttpRequest()
                            loadReq.open("GET", apiURL + '/partner/widget?code=' + document.getElementById("bbanketWidget").getAttribute("data-code"))
                            // loadReq.setRequestHeader("Accept", `application/json`)
                            loadReq.send()
                            loadReq.onload = () => {
                                if (loadReq.response == '"incorected host"' || loadReq.response == '"widget not found"') return
                                const data = JSON.parse(loadReq.response)

                                viewType = data.view_style ?? 1
                                // айди ресторана (для метрики)
                                restaurantId = data.id

                                if (viewType === 2 && document.documentElement.clientWidth < 991) {
                                    document.getElementById('bbanketIcon').classList.remove('bbanketIcon_visible')
                                }

                                if (viewType === 3) {
                                    document.getElementById('bbanketIcon').classList.remove('bbanketIcon_visible')
                                }

                                // Появление иконки
                                document.getElementById('bbanketIcon').style.display = "block"
                                setTimeout(() => {
                                    document.getElementById('bbanketIcon').classList.add("bbanketIcon_load")
                                }, 1)

                                document.querySelectorAll(".sm-modal").forEach((modal) => modal.style.display = "block")

                                // Цвет бэкграунда
                                if (data.bg_color) {
                                    document.querySelector('.bbanketIcon__descr').style.backgroundColor = data.bg_color
                                    document.querySelector('.bbanketIcon__descr').style.boxShadow = `0px 4px 20px ${data.bg_color}80`
                                }
                                // Верхний текст
                                if (data.first_string) {
                                    document.querySelector('#bbanketIcon .bbanketIcon__sub').innerHTML = data.first_string
                                }
                                // Нижний текст
                                if (data.first_string) {
                                    document.querySelector('#bbanketIcon .bbanketIcon__title').innerHTML = data.second_string
                                }
                                // Цвет текста
                                if (data.text_color) {
                                    document.querySelector('#bbanketIcon .bbanketIcon__sub').style.color = data.text_color
                                    document.querySelector('#bbanketIcon .bbanketIcon__title').style.color = data.text_color
                                }
                                // Присвоение айди модалке
                                document.getElementById("bbanketModal").setAttribute("data-id", data.id)
                                // Виджет слева\справа
                                if (data.side === 0) {
                                    document.getElementById('bbanketWidget').classList.add('to-left')
                                }
                                // Значение в пикселях слева\справа
                                if (data.h_offset) {
                                    if (data.side === 0) {
                                        document.getElementById('bbanketIcon').style.left = data.h_offset
                                    } else {
                                        document.getElementById('bbanketIcon').style.right = data.h_offset
                                    }
                                }
                                // Значение в пикселях снизу
                                if (data.v_offset) {
                                    document.getElementById('bbanketIcon').style.bottom = data.v_offset
                                }

                                 // Наполнение селекта событий
                                let selectEvent = document.getElementById('bbanket_events').parentNode.querySelector('.simple-select1__drop .simple-select1__list')
                                //  renderCalendarSelect(data.min_capacity, data.max_capacity, true)
                                 // Очищаем селект
                                selectEvent.innerHTML = ''
                                 // Наполняем селект
                                let eventListHtml = ""

                                for (const key in data.event_types) {
                                    const liEl = `<li class="simple-select1__item" data-value="${key}" role="option">${data.event_types[key]}</li>`
                                    eventListHtml = eventListHtml + liEl
                                }
                                resetSelect1('.simple-select1_event')

                                selectEvent.innerHTML = eventListHtml

                                // Получение мин\макс гостей и инфы о менеджере
                                reqRest.open("GET", apiURL + '/restaurant?restaurant_id=' + data.id)
                                reqRest.send()
                                reqRest.onload = () => {
                                    const data = JSON.parse(reqRest.response)
                                        document.querySelector('.bbanketModal__name').innerHTML = data.owner.name
                                        document.querySelector('.bbanketModal__photo img').setAttribute('src', data.owner.image)

                                        metrikaDopInfoFcn = () => {
                                            ym(bbanketMetrikaIdInc,'userParams', {RestaurantID: data.id, RestaurantStatus: data.manager_activation})
                                        }

                                        // Название ресторана
                                        // document.querySelector('.bbanketModal__calc-maintitle').innerText = data.name

                                        // Наполнение селекта гостей
                                        let select = document.getElementById('bbanket_guests').parentNode.querySelector('.simple-select1__drop .simple-select1__list')
                                        renderCalendarSelect(data.min_capacity, data.max_capacity, true)
                                        // Очищаем селект
                                        select.innerHTML = ''
                                        // Наполняем селект
                                        let guestListHtml = ""
                                        for (let index = data.min_capacity; index <= data.max_capacity; index++) {
                                            const liEl = `<li class="simple-select1__item ${index === 1 ? 'is-active': ""}" data-value="${index}" role="option">${index} гостей</li>`
                                            guestListHtml = guestListHtml + liEl
                                        }
                                        select.innerHTML = guestListHtml
                                        //
                                        document.getElementById("calc_main_guest").setAttribute('data-default-value', data.min_capacity !== 0 ? data.min_capacity : 1)
                                        // Прицепить оброботчик к кнопкам селекта
                                        document.querySelectorAll('.simple-select1 .simple-select1__item').forEach((btn) => btn.addEventListener("click", SimpleSelectClickButton))
                                        resetSelect1('.simple-select1_guest')
                                        //

                                        // Открытие виджета, если в адресной строке есть #bbanketwo
                                        if (document.location.href.search('bbanketwo') !== -1) {
                                            document.getElementById('bbanketIcon').click()
                                        }
                                    }
                                // Получение залов
                                    reqRooms.open("GET", apiURL + '/rooms?restaurant_id=' + data.id)
                                    reqRooms.send()
                                    reqRooms.onload = () => {
                                        const data = JSON.parse(reqRooms.response)
                                        // Наполнение селекта гостей
                                        let roomsGrid = document.querySelector('.bbanketModal__calc-grid')
                                        // Очищаем блок залов
                                        roomsGrid.innerHTML = ''
                                        // Наполняем блок залов
                                        let roomsGridHtml = ""
                                        data.map((el) => {
                                            roomsGridHtml = roomsGridHtml +
                                                `<div class="bbanketModal__calc-zal">
                                                    <div class="calculation__zal">
                                                        <div class="calculation__zal-cont">
                                                            <div class="calculation__zal-left" data-id=${el.id}>
                                                                <div class="calculation__zal-img" data-id=${el.id}>
                                                                    <img src=${el.image} alt="">
                                                                    <svg width="24" height="24" data-id="1" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M13.5 9H12V7.5C12 7.10218 11.842 6.72064 11.5607 6.43934C11.2794 6.15804 10.8978 6 10.5 6C10.1022 6 9.72064 6.15804 9.43934 6.43934C9.15804 6.72064 9 7.10218 9 7.5V9H7.5C7.10218 9 6.72064 9.15804 6.43934 9.43934C6.15804 9.72064 6 10.1022 6 10.5C6 10.8978 6.15804 11.2794 6.43934 11.5607C6.72064 11.842 7.10218 12 7.5 12H9V13.5C9 13.8978 9.15804 14.2794 9.43934 14.5607C9.72064 14.842 10.1022 15 10.5 15C10.8978 15 11.2794 14.842 11.5607 14.5607C11.842 14.2794 12 13.8978 12 13.5V12H13.5C13.8978 12 14.2794 11.842 14.5607 11.5607C14.842 11.2794 15 10.8978 15 10.5C15 10.1022 14.842 9.72064 14.5607 9.43934C14.2794 9.15804 13.8978 9 13.5 9Z" fill="white"></path>
                                                                        <path d="M23.561 21.4388L18.9 16.7768C20.4983 14.6425 21.2286 11.982 20.9441 9.33077C20.6595 6.67959 19.3811 4.23463 17.3663 2.48811C15.3515 0.741587 12.7499 -0.176819 10.0852 -0.0822231C7.42044 0.0123731 4.89048 1.11295 3.0046 2.99794C1.11871 4.88294 0.0169487 7.41238 -0.0789023 10.0771C-0.174753 12.7418 0.742428 15.3438 2.488 17.3595C4.23358 19.3751 6.67793 20.6546 9.32898 20.9404C11.98 21.2262 14.6409 20.4971 16.776 18.8998L21.439 23.5628C21.7204 23.8442 22.1021 24.0023 22.5 24.0023C22.898 24.0023 23.2796 23.8442 23.561 23.5628C23.8424 23.2814 24.0005 22.8998 24.0005 22.5018C24.0005 22.1039 23.8424 21.7222 23.561 21.4408V21.4388ZM10.5 17.9998C9.01664 17.9998 7.5666 17.56 6.33323 16.7359C5.09986 15.9118 4.13857 14.7404 3.57091 13.37C3.00325 11.9995 2.85473 10.4915 3.14412 9.03666C3.4335 7.5818 4.14781 6.24543 5.1967 5.19654C6.2456 4.14764 7.58197 3.43334 9.03683 3.14395C10.4917 2.85456 11.9997 3.00308 13.3701 3.57074C14.7406 4.1384 15.9119 5.09969 16.736 6.33306C17.5601 7.56643 18 9.01648 18 10.4998C17.9979 12.4883 17.207 14.3947 15.801 15.8008C14.3949 17.2069 12.4885 17.9977 10.5 17.9998Z" fill="white"></path>
                                                                    </svg>
                                                                </div>
                                                                <button type="button" class="calculation__zal-more" href="#" data-id=${el.id}>
                                                                    Подробнее о зале
                                                                </button>
                                                            </div>
                                                            <div class="calculation__zal-right">
                                                                <div class="calculation__zal-title">${el.name}</div>
                                                                <div class="calculation__zal-text">
                                                                    ${el.short_description}
                                                                </div>
                                                                <label class="halls__el-btn btn" href="#" data-id=${el.id} data-name="${el.name}">
                                                                    Выбрать зал
                                                                    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M8.9833 6.26233L6.30639 3.58717C6.19532 3.48674 6.04988 3.43287 5.90019 3.43672C5.7505 3.44056 5.60802 3.50182 5.50224 3.60782C5.39647 3.71381 5.33552 3.85642 5.33199 4.00612C5.32846 4.15582 5.38264 4.30114 5.4833 4.412L8.15847 7.08717C8.26783 7.19656 8.32926 7.34491 8.32926 7.49958C8.32926 7.65426 8.26783 7.80261 8.15847 7.912L5.4833 10.5872C5.37392 10.6966 5.31251 10.845 5.31256 10.9998C5.31262 11.1545 5.37414 11.3029 5.4836 11.4123C5.59305 11.5217 5.74148 11.5831 5.89622 11.583C6.05096 11.583 6.19934 11.5215 6.30872 11.412L8.9833 8.73684C9.31138 8.40866 9.49568 7.96362 9.49568 7.49958C9.49568 7.03555 9.31138 6.59051 8.9833 6.26233Z" fill="white"></path>
                                                                    </svg>
                                                                    <input type="radio" name="room_id" value=${el.id} hidden data-min=${el.min_capacity} data-max=${el.max_capacity}>
                                                                </label>
                                                                <div class="calculation__zal-closed">
                                                                    Не подходит для выбранного количества гостей
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div >`
                                        })

                                        roomsGrid.innerHTML = roomsGridHtml
                                        //

                                         // События для добавленных залов
                                        const zalButtons = document.querySelectorAll(".bbanketModal__calc-grid input")
                                        const lookZalButtons = document.querySelectorAll(".calculation__zal-more, .calculation__zal-left")

                                        for (let index = 0; index < zalButtons.length; index++) {
                                            const btn = zalButtons[index];
                                            btn.addEventListener("change", zalBlockClick)
                                            btn.addEventListener("click", zalBlockClick)
                                        }

                                        for (let index = 0; index < lookZalButtons.length; index++) {
                                            const btn = lookZalButtons[index];
                                            btn.addEventListener("click", LookRoomInfo)
                                        }
                                        //
                                    }
                            }

                            // Сабмит первой формы
                            function firstFormSubmit (e) {
                                e.preventDefault();

                                // Отправка кода
                                if (!firstForm.classList.contains('sendedCode')) {
                                    // Первый шан
                                    let validate = true

                                    // Metrika Пользователь нажал Зарегистрироваться До ввода номера телефона
                                    if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                        ym(bbanketMetrikaIdInc,'reachGoal','vidgetauth1')
                                        metrikaDopInfoFcn()
                                    }


                                    if (document.querySelector(".phone-mask").value.length !== 18) {
                                        validate = false
                                        document.querySelector(".phone-mask").parentNode.classList.add('wrong')
                                    }

                                    if (validate) {
                                        let createClientReq = new XMLHttpRequest()
                                        createClientReq.open("POST",  apiURL + '/widget/client/create')
                                        createClientReq.setRequestHeader("Accept", `application/json`)
                                        createClientReq.setRequestHeader("Content-Type", `application/json`)
                                        createClientReq.send(JSON.stringify({
                                            phone: document.querySelector(".phone-mask").value,
                                            widget_code: document.getElementById("bbanketWidget").getAttribute("data-code"),
                                        }))
                                        createClientReq.onload = () => {
                                            if (typeof createClientReq.response === "string") {
                                                firstForm.classList.add('sendedCode')
                                                document.querySelector(".bbanketModal__form-sendAgain").style.display = "block"
                                                document.querySelector(".input-block.smsCode").style.display = "block"
                                                timeMinut = parseInt(timerInput)
                                                timerShow.innerHTML = "30";
                                                document.querySelector('.code-mask').focus()
                                            } else {
                                                const data = JSON.parse(createClientReq.response)
                                                if (!data.order_code) return
                                                document.getElementById('bbanketModal').setAttribute('data-order', data.order_code)
                                                document.querySelector(".phone-mask").parentNode.classList.add('wrong')
                                                document.querySelector(".phone-mask").parentNode.querySelector('.message').innerText = 'Данный номер уже зарегистрирован'
                                            }
                                        },
                                        createClientReq.onerror = function () {
                                            document.querySelector(".phone-mask").parentNode.classList.add('wrong')
                                            const data = JSON.parse(createClientReq.response)
                                            if (data?.responseJSON?.message === "already verified") {
                                                document.querySelector(".phone-mask").parentNode.querySelector('.message').innerText = 'Данный номер уже зарегистрирован'
                                            } else {
                                                document.querySelector(".phone-mask").parentNode.querySelector('.message').innerText = 'Ошибка сервера'
                                            }
                                        }
                                    }
                                } else if (firstForm.classList.contains('sendedCode')) {
                                    // Второй шаг
                                    let validate = true,
                                        codeInp = document.querySelector(".code-mask")

                                    // Metrika Пользователь нажал Зарегистрироваться После ввода номера телефона
                                    if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                        ym(bbanketMetrikaIdInc,'reachGoal','vidgetauth2')
                                        metrikaDopInfoFcn()
                                    }


                                    if (codeInp.value.length < 4 || codeInp.value.search("_") != -1) {
                                        validate = false
                                        codeInp.parentNode.classList.add('wrong')
                                    }

                                    if (document.querySelector(".phone-mask").value.length !== 18) {
                                        validate = false
                                        document.querySelector(".phone-mask").parentNode.add('wrong')
                                    }

                                    if (validate) {
                                        let dataAjax = {
                                            phone: document.querySelector(".phone-mask").value,
                                            code: codeInp.value,
                                            widget_code: document.getElementById('bbanketWidget').getAttribute('data-code')
                                        }

                                    // Переход ко второй формe
                                        let verifylientReq = new XMLHttpRequest()
                                        verifylientReq.open("POST",  apiURL + '/widget/client/verify')
                                        verifylientReq.setRequestHeader("Accept", `application/json`)
                                        verifylientReq.setRequestHeader("Content-Type", `application/json`)
                                        verifylientReq.send(JSON.stringify(dataAjax))
                                        verifylientReq.onload = () => {
                                            if (verifylientReq.response) {
                                                const data = JSON.parse(verifylientReq.response)
                                                if (data?.order_code !== undefined) {
                                                    setCookieBbanket("noStartModal", "true", {"max-age": 7260000})
                                                    setCookieBbanket("currentStep", 2, {"max-age": 7260000})
                                                    setCookieBbanket("regOrderCode", data.order_code, {"max-age": 7260000})
                                                    // Переход ко второй формe
                                                    orderCodeBanket = data.order_code
                                                    firstForm.classList.remove('sendedCode')
                                                    document.querySelector(".bbanketModal__conditions").style.display = "none"
                                                    document.querySelector(".bbanketModal__form_first").style.display = "none"
                                                    document.getElementById('bbanketModal').setAttribute('data-order', data.order_code)
                                                    document.querySelector(".bbanketModal__form_second").style.display = "block"
                                                }
                                            } else {
                                                document.querySelector(".code-mask").parentNode.classList.add('wrong')
                                            }
                                        },
                                        verifylientReq.onerror = () => {
                                            const data = JSON.parse(verifylientReq.response)
                                            console.log('Verify Error',data);
                                            document.querySelector(".code-mask").parentNode.classList.add('wrong')
                                        }
                                    }
                                }
                            }
                            // Таймер и повторная отправка
                            document.querySelector('.bbanketModal__form-send').addEventListener("click", function (e) {
                                e.preventDefault()

                                let validate = true

                                const sendAgainBlock = document.querySelector(".bbanketModal__form-sendAgain")

                                if (document.querySelector(".phone-mask").value.length !== 18) {
                                    validate = false
                                    document.querySelector(".phone-mask").parentNode.classList.add('wrong')
                                }

                                let dataAjax = {
                                    phone: document.querySelector(".phone-mask").value,
                                    widget_code: document.getElementById("bbanketWidget").getAttribute("data-code"),
                                }

                                // Доп метка чтоб отправлять смс (?)
                                // if (sendAgainBlock.classList.contains("secondSended")) {
                                    dataAjax.need_sms = 1
                                // }

                                if (validate) {
                                    let createClientReq = new XMLHttpRequest()
                                    createClientReq.open("POST",  apiURL + '/widget/client/create')
                                    createClientReq.setRequestHeader("Accept", `application/json`)
                                    createClientReq.setRequestHeader("Content-Type", `application/json`)
                                    createClientReq.send(JSON.stringify(dataAjax))
                                    createClientReq.onload = () => {
                                        // Добавление класса что второй раз звонок отправили и отправлять СМС
                                        if (!sendAgainBlock.classList.contains("secondSended")) {
                                            sendAgainBlock.classList.add("secondSended")
                                            document.getElementById("timerText").innerText = "Не пришел звонок? Отправить СМС через "
                                            document.querySelector(".bbanketModal__form-send").innerText = "Не пришел звонок? Отправить СМС"
                                        } else if (sendAgainBlock.classList.contains("secondSended")) {
                                            document.getElementById("timerText").innerText = "Отправить СМС повторно можно через "
                                            document.querySelector(".informCall").innerText = "Вам поступит СМС с кодом подтверждения. Введите его в поле ниже:"
                                            document.querySelector(".smsCode .label").innerText = "Код из СМС"
                                            document.querySelector(".bbanketModal__form-send").innerText = "Отправить СМС повторно"
                                        }
                                        //

                                        timeMinut = parseInt(timerInput)
                                        document.querySelector('.bbanketModal__form-timer').style.display = "block"
                                        document.querySelector('.bbanketModal__form-send').style.display = "none"
                                        timerShow.innerHTML = "30";

                                        setTimeout(() => {
                                            document.querySelector('.code-mask').focus()
                                        }, 50)


                                        timer = setInterval(function () {
                                            seconds = timeMinut%60 // Получаем секунды
                                            // Условие если время закончилось то...
                                            if (timeMinut <= 0) {
                                                // Таймер удаляется
                                                clearInterval(timer);
                                                // Выводит сообщение что время закончилось
                                                document.querySelector('.bbanketModal__form-timer').style.display = "none"
                                                document.querySelector('.bbanketModal__form-send').style.display = "block"
                                            } else { // Иначе
                                                // Создаём строку с выводом времени
                                                let strTimer = `${seconds !== NaN ? seconds : 30}`;
                                                // Выводим строку в блок для показа таймера
                                                timerShow.innerHTML = strTimer;
                                            }
                                            --timeMinut; // Уменьшаем таймер
                                        }, 1000)

                                    }
                                }
                            })

                            timer = setInterval(function () {
                                seconds = timeMinut ? timeMinut%60 : 0 // Получаем секунды
                                // Условие если время закончилось то...
                                if (timeMinut <= 0) {
                                    // Таймер удаляется
                                    clearInterval(timer);
                                    // Выводит сообщение что время закончилось
                                    document.querySelector('.bbanketModal__form-timer').style.display = "none"
                                    document.querySelector('.bbanketModal__form-send').style.display = "block"
                                } else { // Иначе
                                    // Создаём строку с выводом времени
                                    let strTimer = `${seconds}`;
                                    // Выводим строку в блок для показа таймера
                                    timerShow.innerHTML = strTimer;
                                }
                                --timeMinut; // Уменьшаем таймер
                            }, 1000)
                            //
                            // Основная модалка
                            if (document.querySelectorAll('#bbanketModal').length > 0) {
                                bbanketModal = new SmModal({
                                    modalSelector: '#bbanketModal',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                    afterOpen: () => {
                                        setTimeout(function () {
                                            interfaceSlider?.swiperVariable?.update();
                                        }, 50);
                                    },
                                    // afterClose: () => {

                                    // }
                                });
                            }

                            // О зале
                            if (document.querySelectorAll('#about-zal').length > 0) {
                                var aboutZal = new SmModal({
                                    modalSelector: '#about-zal',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                            // Заявка принята
                            if (document.querySelectorAll('#order_confirm').length > 0) {
                                var orderConfirm = new SmModal({
                                    modalSelector: '#order_confirm',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                            // Несохранение данных
                            if (document.querySelectorAll('#data_lost').length > 0) {
                                var dataLost = new SmModal({
                                    modalSelector: '#data_lost',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                            // Уведомления брони даты
                            if (document.querySelectorAll('#broned_modal').length > 0) {
                                var bronedZalAgree = new SmModal({
                                    modalSelector: '#broned_modal',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                             // Бронирование онлайн в данном ресторане недоступно
                            if (document.getElementById('broned_close')) {
                                var bronedZalClose = new SmModal({
                                    modalSelector: '#broned_close',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                             // Бронирование другим пользователем
                            if (document.getElementById('broned_other')) {
                                var bronedZalOther = new SmModal({
                                    modalSelector: '#broned_other',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                             // Модалка со ссылкой на полный расчёт
                            if (document.getElementById('open_full_menu')) {
                                var openFullMenu = new SmModal({
                                    modalSelector: '#open_full_menu',
                                    modalSpeed: 200,
                                    backgroundBlur: false,
                                    closeSelector: '.modal-close, .btn-close',
                                    // backgroundBlurValue: '10px',
                                });
                            }

                            document.querySelector("#broned_modal .btn-close").addEventListener("click", () => bronedZalAgree.closeModal())
                            document.querySelector("#broned_other .btn-close").addEventListener("click", () => bronedZalOther.closeModal())


                            document.querySelector("#order_confirm .btn-close").addEventListener("click", function(e) {
                                orderConfirm.closeModal()
                            })

                            // Клик по оверлею для открытия модалки несохранения данных
                            document.querySelector(".modal-overlay").addEventListener("click", function(e) {
                                dataLost.openModal()
                            })

                            document.querySelector("#closeBbanketModal").addEventListener("click", function(e) {
                                dataLost.closeModal()
                                bbanketModal.closeModal()
                            })



                            // Удаление ошибки при изменении инпута
                            document.querySelectorAll('#bbanketWidget input').forEach((elem) =>{
                                elem.addEventListener("input change", function() {
                                    this.parentNode.classList.remove('wrong')
                                    this.parentNode.parentNode.querySelector('.message').innerText = ''
                                })}
                            )

                            function secondFormSubmit (e) {
                                e.preventDefault();

                                // Metrika Пользователь нажал Завершить регистрацию
                                if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                    ym(bbanketMetrikaIdInc,'reachGoal','vidgetauth3')
                                    metrikaDopInfoFcn()
                                }

                                let name = document.getElementById('bbanket_name'),
                                    date = document.getElementById('registerDatapicker'),
                                    guests = document.getElementById('calc_main_guest');
                                    eventInp = document.getElementById('calc_main_event');
                                // Второй шаг
                                let splitDate = date.value.split('.')
                                let dataAjax = {
                                    name: name.value,
                                    date: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`,
                                    guest_count: guests.value,
                                    event_type_id: eventInp.value,
                                    order_code: document.getElementById('bbanketModal').getAttribute('data-order')
                                }
                                let validate = true

                                if (name.value.length < 2) {
                                    validate = false
                                    name.parentNode.classList.add('wrong')
                                }

                                if (date.value.length !== 10) {
                                    validate = false
                                    date.parentNode.classList.add('wrong')
                                }

                                if (guests.value.length === 0) {
                                    validate = false
                                    guests.parentNode.parentNode.parentNode.classList.add('wrong')
                                } else{
                                    guests.parentNode.parentNode.parentNode.classList.remove('wrong')
                                }

                                if (eventInp.value.length === 0) {
                                    validate = false
                                    eventInp.parentNode.parentNode.parentNode.classList.add('wrong')
                                } else{
                                    eventInp.parentNode.parentNode.parentNode.classList.remove('wrong')
                                }

                                if (validate) {
                                    let orderUpdateReq = new XMLHttpRequest()
                                    orderUpdateReq.open("POST",  apiURL + '/widget/order/update')
                                    orderUpdateReq.setRequestHeader("Accept", `application/json`)
                                    orderUpdateReq.setRequestHeader("Content-Type", `application/json`)
                                    orderUpdateReq.send(JSON.stringify(dataAjax))
                                    orderUpdateReq.onload = () => {
                                        const data = JSON.parse(orderUpdateReq.response)
                                        // Переход на след. шаг
                                        updateFcn(null, data.order_code, date.value, guests.value)
                                    }
                                }
                            }

                            // Обработка сабмита первой формы
                            firstForm.addEventListener('submit', firstFormSubmit)
                            secondForm.addEventListener('submit', secondFormSubmit)

                            // Установка маски на ипуты телефона\кода
                            phone_mask();

                            // Открытие-закрытие календаря
                            document.getElementById("registerDatapicker").addEventListener("click", function (e) {
                                const pickerEl = this.parentNode.querySelector(".air-datepicker")
                                if (pickerEl.style.display === "none") {
                                    pickerEl.style.display = "block"
                                } else {
                                    pickerEl.style.display = "none"
                                }
                            })

                            // Возврат на шаг гостей если нет подходящих залов
                            document.getElementById("zal_empty").addEventListener('click', function(e) {
                                e.preventDefault()
                            })
                            //

                            renderCalendarSelect = function (min, max, zal) {
                                // Наполнение селекта гостей
                                let calendarSelectBody = zal ? document.querySelector('.firstSelect.bbanketModal__calendar-guest .bbanketModal__calendar-select-body') : document.querySelector('.secondSelect.bbanketModal__calendar-guest .bbanketModal__calendar-select-body')
                                calendarSelectBody.innerHTML = ''
                                let calendarSelectBodyHtml = ""
                                for (let index = Number(min); index <= Number(max); index++) {
                                    calendarSelectBodyHtml = calendarSelectBodyHtml + `<button class="${index === parseInt(document.getElementById('calc_main_guest').value) ? "selected" : ""}" data-value=${index}>${index} гостей</button>`
                                }

                                calendarSelectBody.innerHTML = calendarSelectBodyHtml

                                let btns = document.querySelectorAll(".bbanketModal__calendar-select-body button")
                                for (let index = 0; index < btns.length; index++) {
                                    const element = btns[index];
                                    element.addEventListener("click", selectBtnClick)
                                }
                            }

                            zalBlockClick = function () {

                                // Metrika Пользователь выбрал один из залов (нажал на кнопку Выбрать зал)
                                if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                    ym(bbanketMetrikaIdInc,'reachGoal','vidgetchooseroom')
                                    metrikaDopInfoFcn()
                                }

                                let thisRoomId = this.value
                                let zalName = this.parentNode.getAttribute('data-name')

                                document.querySelector('.bbanketModal__calc-noempty .zalname').innerText = zalName
                                document.querySelector('.bbanketModal__calendar-name div').innerText = zalName
                                document.querySelector('.bbanketModal__calendar-name').setAttribute("data-id", thisRoomId)
                                // Запись айди зала в дата атрибут
                                document.querySelector('.bbanketModal__calc-grid').setAttribute("data-room", thisRoomId)
                                // Текст вместимости зала
                                document.querySelector('.bbanketModal__calendar-subname').innerText = `от ${this.getAttribute('data-min')} до ${this.getAttribute('data-max')} человек`
                                // Выбор даты
                                let splitDate = document.getElementById("registerDatapicker").value.split('.')

                                // Наполнение селекта гостей
                                // renderCalendarSelect(this.getAttribute('data-min'), this.getAttribute('data-max'), true)

                                // Убирать дисаблед кнопки, если выбрали другой зал
                                let roomId = getCookieBbanket("selectedBanketZal")
                                if (Number(roomId) !== Number(thisRoomId)) {
                                    document.querySelector('.btn_pink').removeAttribute("disabled")
                                }
                                //

                                checkDatesReq = new XMLHttpRequest()
                                checkDatesReq.open("GET", apiURL + `/check/dates?room_id=${thisRoomId}&order_code=${orderCodeBanket}`)
                                checkDatesReq.send()
                                checkDatesReq.onload = () => {
                                    // Набы не было ошибки в кнсоли
                                    if (checkDatesReq?.response == "") return
                                    //
                                    const data = JSON.parse(checkDatesReq?.response)
                                    setCookieBbanket("currentStep", 4, {
                                        "max-age": 7260000
                                    })
                                    setCookieBbanket("selectedBanketZal", thisRoomId, {
                                        "max-age": 7260000
                                    })
                                    var result = data;

                                    // Обнуление массивов с датой
                                    particalRedDate = [];
                                    redDate = [];
                                    orangeDate = [];
                                    orangeDateThis = [];

                                    document.querySelector(".edit-guests").style.display = "block"
                                    document.getElementById("guest_count1").setAttribute("disabled", "disabled")

                                    for (let index = 0; index < result.length; index++) {
                                        if (result[index]?.date) {
                                            const thisData = result[index],
                                                thisDataOnly = replaceAlll(thisData?.date?.replace(' 00:00:00', ''), '-', '/'), //Выделение самой даты и замена - на ,
                                                thisDataStatus = thisData.status; // Статус зала
                                            var currDate = new Date(thisDataOnly), // Разбивка полученной даты (чтоб сделать месяцы без нулей)
                                                currMonth = currDate.getMonth(),
                                                currYear = currDate.getFullYear(),
                                                currDay = currDate.getDate(),
                                                resultDate = currYear + '.' + currMonth + '.' + currDay; // Сборка даты
                                            // Создание массивов с датой
                                            if (!isNaN(currMonth) || !isNaN(currYear) || !isNaN(currDay)) {
                                                if (thisDataStatus == 3 || thisDataStatus == 4) {
                                                    redDate.push(resultDate);
                                                }
                                                else if (thisDataStatus == 1 && !thisData.made_by_current_client && thisData.is_prereserve) {
                                                    orangeDate.push(resultDate);
                                                }
                                                else if (thisDataStatus == 1 && thisData.made_by_current_client && thisData.is_prereserve) {
                                                    orangeDateThis.push(resultDate);
                                                }
                                            }
                                        }
                                    }
                                    mainDatapicker.update({
                                        inline: true,
                                        onSelect: function ({formattedDate, date}) {
                                            let splitDate = formattedDate.split('.')
                                            let dataAjax = {
                                                room_id: document.getElementsByClassName('bbanketModal__calc-grid')[0].getAttribute('data-room'),
                                                guest_count: document.getElementById('guest_count1').value,
                                                date: `${date.getFullYear()}-${(parseInt(date.getMonth()) + 1) < 10 ? "0" + (parseInt(date.getMonth()) + 1) : parseInt(date.getMonth()) + 1}-${date.getDate() < 10 ? "0" + date.getDate() : date.getDate()}`,
                                                order_code: orderCodeBanket,
                                            }

                                            setTimeout(() => {
                                                let dayEl = document.querySelector(".calendar-mobile .air-datepicker-cell.-day-.-selected-"),
                                                    dateBlock = document.querySelector('.bbanketModal__calendar-date')
                                                if (dayEl.classList.contains('red')) {
                                                    document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} занято`)
                                                    dateBlock.classList.add('red')
                                                    dateBlock.classList.remove('orange')
                                                    document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                                    document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                                } else if (dayEl.classList.contains('particalRed')) {
                                                    document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} частично занято`)
                                                    dateBlock.classList.add('red')
                                                    dateBlock.classList.remove('orange')
                                                    document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                                    document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                                } else if (dayEl.classList.contains('otherClient')) {
                                                    document.querySelector('.bbanketModal__calendar-date').innerHTML = `${date.getDate()} ${months[parseInt(splitDate[1])-1]} - предварительная бронь`
                                                    dateBlock.classList.add('orange')
                                                    dateBlock.classList.remove('red')
                                                    document.getElementById("btn_broned_mob").classList.add("bronedOther")
                                                    document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                                    document.querySelector(".you_broned_date").innerText = formattedDate
                                                    document.querySelector(".you_broned_zal").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                                } else if (dayEl.classList.contains('thisClient')) {
                                                    document.querySelector('.bbanketModal__calendar-date').innerHTML = `${date.getDate()} ${months[parseInt(splitDate[1])-1]} - ваша заявка отправлена`
                                                    dateBlock.classList.add('orange')
                                                    dateBlock.classList.remove('red')
                                                    document.getElementById("btn_broned_mob").setAttribute("disabled", "disabled")
                                                    document.querySelector(".you_broned_date").innerText = formattedDate
                                                    document.querySelector(".you_broned_zal").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                                    document.getElementById("btn_broned_mob").classList.remove("bronedOther")
                                                } else {
                                                    document.querySelector('.bbanketModal__calendar-date').innerHTML = (`${date.getDate()} ${months[parseInt(splitDate[1])-1]} свободно`)
                                                    dateBlock.classList.remove('red')
                                                    dateBlock.classList.remove('orange')
                                                    document.getElementById("btn_broned_mob").removeAttribute("disabled")
                                                    document.getElementById("btn_broned_mob").classList.remove("bronedOther")
                                                }
                                            }, 120)

                                            checkDateAjax(dataAjax)

                                        },
                                        prevHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.625 23.7498C13.8723 23.75 14.1141 23.6768 14.3198 23.5395C14.5255 23.4023 14.6859 23.2071 14.7806 22.9787C14.8753 22.7502 14.9002 22.4988 14.852 22.2563C14.8039 22.0137 14.6848 21.7909 14.51 21.616L8.77749 15.8835C8.54315 15.6491 8.41151 15.3312 8.41151 14.9998C8.41151 14.6683 8.54315 14.3504 8.77749 14.116L14.5 8.38351C14.7344 8.14896 14.866 7.83091 14.8659 7.49932C14.8658 7.16774 14.7339 6.84978 14.4994 6.61539C14.2648 6.38101 13.9468 6.24939 13.6152 6.24951C13.2836 6.24963 12.9656 6.38146 12.7312 6.61602L6.99999 12.3485C6.29698 13.0517 5.90204 14.0054 5.90204 14.9998C5.90204 15.9941 6.29698 16.9478 6.99999 17.651L12.7325 23.3835C12.8496 23.5006 12.9888 23.5933 13.142 23.6562C13.2952 23.7191 13.4594 23.7509 13.625 23.7498Z" fill="#675BE1"/><path d="M22.375 23.75C22.6214 23.7482 22.8618 23.6737 23.066 23.5356C23.2702 23.3976 23.429 23.2023 23.5225 22.9743C23.616 22.7463 23.64 22.4957 23.5915 22.2541C23.543 22.0125 23.4242 21.7906 23.25 21.6163L16.6387 15L23.25 8.38377C23.4657 8.14621 23.5818 7.83481 23.5742 7.51402C23.5666 7.19324 23.436 6.88765 23.2094 6.66051C22.9827 6.43338 22.6774 6.30211 22.3566 6.29387C22.0359 6.28563 21.7242 6.40107 21.4862 6.61627L13.9862 14.1163C13.7519 14.3507 13.6202 14.6686 13.6202 15C13.6202 15.3315 13.7519 15.6494 13.9862 15.8838L21.4862 23.3838C21.6028 23.5004 21.7414 23.5929 21.894 23.6557C22.0465 23.7186 22.21 23.7506 22.375 23.75Z" fill="#675BE1"/></svg>',
                                        nextHtml: '<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.375 23.7498C16.1277 23.75 15.8859 23.6768 15.6802 23.5395C15.4745 23.4023 15.3141 23.2071 15.2194 22.9787C15.1247 22.7502 15.0998 22.4988 15.148 22.2563C15.1961 22.0137 15.3152 21.7909 15.49 21.616L21.2225 15.8835C21.4568 15.6491 21.5885 15.3312 21.5885 14.9998C21.5885 14.6683 21.4568 14.3504 21.2225 14.116L15.5 8.38351C15.2656 8.14896 15.134 7.83091 15.1341 7.49932C15.1342 7.16774 15.2661 6.84978 15.5006 6.61539C15.7352 6.38101 16.0532 6.24939 16.3848 6.24951C16.7164 6.24963 17.0344 6.38146 17.2688 6.61602L23 12.3485C23.703 13.0517 24.098 14.0054 24.098 14.9998C24.098 15.9941 23.703 16.9478 23 17.651L17.2675 23.3835C17.1504 23.5006 17.0112 23.5933 16.858 23.6562C16.7048 23.7191 16.5406 23.7509 16.375 23.7498Z" fill="#675BE1"/><path d="M7.62505 23.75C7.37861 23.7482 7.13819 23.6737 6.93401 23.5356C6.72983 23.3976 6.57101 23.2023 6.4775 22.9743C6.38399 22.7463 6.35998 22.4957 6.40847 22.2541C6.45696 22.0125 6.5758 21.7906 6.75005 21.6163L13.3613 15L6.75005 8.38377C6.53434 8.14621 6.41825 7.83481 6.4258 7.51402C6.43336 7.19324 6.56399 6.88765 6.79064 6.66051C7.01729 6.43338 7.3226 6.30211 7.64337 6.29387C7.96414 6.28563 8.27579 6.40107 8.5138 6.61627L16.0138 14.1163C16.2481 14.3507 16.3798 14.6686 16.3798 15C16.3798 15.3315 16.2481 15.6494 16.0138 15.8838L8.5138 23.3838C8.39716 23.5004 8.25857 23.5929 8.10604 23.6557C7.9535 23.7186 7.79003 23.7506 7.62505 23.75Z" fill="#675BE1"/></svg>',
                                        onRenderCell: function ({date, cellType}) {
                                            var thisStringDate = date.getFullYear() + '.' + date.getMonth() + '.' + date.getDate();
                                            for (var i = 0; i < redDate.length; i++) {
                                                if (redDate[i] == thisStringDate && cellType == 'day') {
                                                    return {
                                                        classes: 'red',
                                                    }
                                                }
                                            }
                                            for (var i = 0; i < orangeDate.length; i++) {
                                                if (orangeDate[i] == thisStringDate && cellType == 'day') {
                                                    return {
                                                        classes: 'orange otherClient',
                                                    }
                                                }
                                            }
                                            for (var i = 0; i < orangeDateThis.length; i++) {
                                                if (orangeDateThis[i] == thisStringDate && cellType == 'day') {
                                                    return {
                                                        classes: 'orange thisClient',
                                                    }
                                                }
                                            }
                                            for (var i = 0; i < particalRedDate.length; i++) {
                                                var currentDate = date.getDate();
                                                if (particalRedDate[i] == thisStringDate && cellType == 'day') {
                                                    return {
                                                        html: currentDate + '<span class="dp-note"></span>',
                                                        classes: 'particalRed',
                                                    }
                                                }
                                            }

                                            // Окрас в серый прошедших дней
                                            if ((date.getMonth() < currentMounth && date.getFullYear() <= currentYear || date.getDate() < currentDay && date.getMonth() == currentMounth && date.getFullYear() == currentYear || date.getFullYear() < currentYear) && cellType == 'day') {
                                                return {
                                                    classes: 'grey-col',
                                                }
                                            }
                                            // Окрас в серый прошедших месяцев
                                            if ((date.getMonth() < currentMounth && date.getFullYear() == currentYear || date.getFullYear() < currentYear) && cellType == 'month') {
                                                return {
                                                    classes: 'grey-col',
                                                }
                                            }
                                            // Окрас в серый прошедших лет
                                            if ((date.getFullYear() < currentYear) && cellType == 'year') {
                                                return {
                                                    classes: 'grey-col',
                                                }
                                            }
                                        },
                                    });

                                    mainDatapicker.selectDate(new Date(`${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`))
                                    document.querySelector('.bbanketModal__calc-room').classList.add('selected')
                                    document.querySelector('.bbanketModal__calc-grid').style.display = "none"
                                    document.querySelector('.bbanketModal__calc-noempty').style.display = "block"
                                    document.querySelector('.bbanketModal__calc-empty').style.display = "none"
                                    document.querySelector('.bbanketModal__calendar-wp').removeAttribute("style")
                                    document.querySelector('.bbanketModal__calendar').removeAttribute("style")
                                }

                            }

                            document.querySelector(".bbanketModal__calendar-link a").addEventListener("click", function (e) {
                                e.preventDefault()

                                // Metrika Пользователь нажал Открыть полное меню
                                if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                    ym(bbanketMetrikaIdInc,'reachGoal','vidgetmenu')
                                    metrikaDopInfoFcn()
                                }

                                if (this.getAttribute("disabled")) return
                                document.querySelector(".bbanketModal__calendar-option").setAttribute("disabled", "disabled")

                                let splitDate = document.getElementById("datapicker").value.split('.')
                                    dataAjax = {
                                    room_id: document.querySelector('.bbanketModal__calc-grid').getAttribute('data-room'),
                                    guest_count: document.getElementById("guest_count1").value,
                                    date: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`,
                                    widget_type: "widget",
                                    order_code: orderCodeBanket,
                                },
                                    createReq = new XMLHttpRequest()
                                // Забеляем типа загрузка
                                this.parentNode.style.opacity = 0.6

                                createReq.open("POST",  apiURL + '/widget/option/create')
                                createReq.setRequestHeader("Accept", `application/json`)
                                createReq.setRequestHeader("Content-Type", `application/json`)
                                createReq.send(JSON.stringify(dataAjax))
                                createReq.onload = () => {
                                    document.querySelector(".bbanketModal__calendar-option").removeAttribute("disabled")
                                    const data = JSON.parse(createReq.response)
                                    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
                                    // Проверка на сафари, если он - открывать модалку со ссылкой
                                    // if (isSafari) {
                                        openFullMenu.openModal()
                                        document.querySelector("#open_full_menu a").setAttribute("href", data.link)
                                    // } else {
                                    //     Object.assign(document.createElement("a"), {
                                    //         target: "_blank",
                                    //         href: data.link
                                    //         }).click();
                                    // }

                                    // Убираем забеление
                                    this.parentNode.removeAttribute("style")
                                }

                                let clickReqClick = new XMLHttpRequest()
                                let dataAjax = {
                                    widget_code: document.getElementById("bbanketWidget").getAttribute("data-code")
                                }
                                clickReqClick.open("POST",  apiURL + '/widget/increase/full-menu-click')
                                clickReqClick.setRequestHeader("Accept", `application/json`)
                                clickReqClick.setRequestHeader("Content-Type", `application/json`)
                                clickReqClick.send(JSON.stringify(dataAjax))
                            })

                            // Клик по кнопке забронировать
                            document.getElementById("btn_broned_mob").addEventListener("click", function (e) {
                                e.preventDefault()

                                // Metrika Пользователь нажал Оставить заявку
                                if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                    ym(bbanketMetrikaIdInc,'reachGoal','vidgetrequest')
                                    metrikaDopInfoFcn()
                                }

                                // console.log("dataAjax", dataAjax);
                                if (this.classList.contains("bronedOther")) {
                                    bronedZalOther.openModal()
                                    document.querySelector("#broned_other .broned_zal_other").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                    document.querySelector("#broned_other .broned_date_other").innerText = document.getElementById("datapicker").value
                                } else if (prevReserve) {
                                    document.querySelector("#broned_modal .broned_zal").innerText = document.querySelector(".bbanketModal__calendar-name div").innerText
                                    document.querySelector("#broned_modal .broned_date").innerText = document.getElementById("datapicker").value
                                    document.querySelector("#broned_modal .broned_guests").innerText = document.getElementById("guest_count1").value
                                    bronedZalAgree.openModal()
                                } else {
                                    bronedZalClose.openModal()
                                }
                                let clickReq = new XMLHttpRequest()
                                let dataAjax = {
                                    widget_code: document.getElementById("bbanketWidget").getAttribute("data-code")
                                }
                                clickReq.open("POST",  apiURL + '/widget/increase/reserve-click')
                                clickReq.setRequestHeader("Accept", `application/json`)
                                clickReq.setRequestHeader("Content-Type", `application/json`)
                                clickReq.send(JSON.stringify(dataAjax))
                            })

                            document.getElementById("bronedAgree").addEventListener("click", function (e) {
                                e.preventDefault()

                                this.setAttribute("disabled", "disabled")
                                let splitDate = document.getElementById("datapicker").value.split('.')
                                document.getElementById("registerDatapicker").value = document.getElementById("datapicker").value
                                let dataAjax = {
                                    room_id: document.querySelector('.bbanketModal__calc-grid').getAttribute('data-room'),
                                    guest_count: document.getElementById("guest_count1").value,
                                    date: `${splitDate[2]}-${splitDate[1]}-${splitDate[0]}`,
                                    widget_type: "widget",
                                    order_code: orderCodeBanket,
                                },
                                    createReq = new XMLHttpRequest(),
                                    prevReservReq = new XMLHttpRequest()
                                    clickReqClick = new XMLHttpRequest()

                                // Счётчик клико по кнопке бронирования
                                let dataAjaxClick = {
                                    widget_code: document.getElementById("bbanketWidget").getAttribute("data-code")
                                }

                                clickReqClick.open("POST",  apiURL + '/widget/increase/reserve-btn-click')
                                clickReqClick.setRequestHeader("Accept", `application/json`)
                                clickReqClick.setRequestHeader("Content-Type", `application/json`)
                                clickReqClick.send(JSON.stringify(dataAjaxClick))

                                createReq.open("POST",  apiURL + '/widget/option/create')
                                createReq.setRequestHeader("Accept", `application/json`)
                                createReq.setRequestHeader("Content-Type", `application/json`)
                                createReq.send(JSON.stringify(dataAjax))
                                createReq.onload = () => {
                                    const data = JSON.parse(createReq.response)

                                    prevReservReq.open("POST",  apiURL + '/option/prev-reserve/' + data.option_code)
                                    prevReservReq.setRequestHeader("Accept", `application/json`)
                                    prevReservReq.setRequestHeader("Content-Type", `application/json`)
                                    prevReservReq.send(JSON.stringify({widget_type: "widget"}))
                                    prevReservReq.onload = () => {
                                        // const data = JSON.parse(prevReservReq.response)
                                        document.querySelector('.btn_pink').setAttribute("disabled", "disabled")
                                        const zalInputs = document.querySelectorAll(".bbanketModal__calc-grid input")
                                        // Кликаем по залу, что по новой получить даты
                                        for (let index = 0; index < zalInputs.length; index++) {
                                            const el = zalInputs[index];
                                            if (el.checked) {
                                                el.click()
                                            }
                                        }
                                        setTimeout(()=> {
                                            this.removeAttribute("disabled")
                                            bronedZalAgree.closeModal()
                                            mainDatapicker.selectDate(new Date(`${splitDate[2]}/${splitDate[1]}/${splitDate[0]}`))
                                        }, 120)
                                    }
                                }
                            })

                            document.querySelector('.edit-zal').addEventListener('click', function (e) {
                                e.preventDefault()
                                document.querySelector('.bbanketModal__calc-room').classList.remove('selected')
                                document.querySelector('.bbanketModal__calc-grid').removeAttribute("style")
                                document.querySelector('.bbanketModal__calendar-wp').style.display = "none"
                                document.querySelector('.bbanketModal__calendar').style.display = "none"
                                Array.prototype.slice.call(document.querySelectorAll(".bbanketModal__calc-grid input")).forEach((el) => el.checked = false)
                                document.querySelector(".zalname").innerText = ""
                                document.querySelector(".bbanketModal__calendar-guest").classList.remove("select-open")
                            })
                            document.querySelector('.edit-guests').addEventListener('click', function (e) {
                                e.preventDefault()
                                document.querySelector('.bbanketModal__calc-room').classList.remove('selected')
                                document.querySelector('.bbanketModal__calc-grid').removeAttribute("style")
                                document.querySelector('.bbanketModal__calendar-wp').style.display = "none"
                                document.querySelector('.bbanketModal__calendar').style.display = "none"
                                Array.prototype.slice.call(document.querySelectorAll(".bbanketModal__calc-grid input")).forEach((el) => el.checked = false)
                                document.querySelector(".edit-guests").style.display = "none"
                                document.querySelector(".zalname").innerText = ""
                                document.getElementById("guest_count1").removeAttribute("disabled")

                                document.querySelector(".bbanketModal__calendar-guest").classList.remove("select-open")
                            })

                            // Селектор гостей в финальном окне
                            document.querySelector('.bbanketModal__calendar-guest.firstSelect').addEventListener('click', function (e) {
                                e.preventDefault()
                                if (this.classList.contains("select-open")) {
                                    this.classList.remove("select-open")
                                } else {
                                    this.classList.add("select-open")
                                }
                            })

                            function selectBtnClick (e) {
                                e.preventDefault()
                                let inpBlock = document.getElementById('guest_count1'),
                                    btnVal = this.getAttribute('data-value')

                                inpBlock.value = btnVal
                                inpBlock.nextElementSibling.querySelector('.guests').innerText = btnVal
                                document.querySelector('.bbanketModal__calendar-nums .guests').innerText = btnVal
                                inpBlock.nextSibling.nextSibling.nextSibling.nextSibling.querySelector('.selected')?.classList?.remove('selected')
                                let allButtons = inpBlock.nextSibling.nextSibling.nextSibling.nextSibling.getElementsByTagName(`button`)

                                for (let index = 0; index < allButtons.length; index++) {
                                    const btn = allButtons[index];
                                    if (btn.getAttribute("data-value") == btnVal) {
                                        btn.classList.add("selected")
                                    }
                                }

                                // Скрытие\Показ залов
                                // let zalsVisible = 0,
                                let allRooms = document.querySelectorAll(".bbanketModal__calc-grid label input")

                                for (let index = 0; index < allRooms.length; index++) {
                                    const room = allRooms[index];
                                    if (parseInt(room.getAttribute('data-min')) <= btnVal && parseInt(room.getAttribute('data-max')) >= btnVal) {
                                        room.parentNode.removeAttribute("style")
                                        room.parentNode.parentNode.querySelector(".calculation__zal-closed").removeAttribute("style")
                                        room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "-1"
                                    } else {
                                        room.parentNode.style.display = "none"
                                        room.parentNode.parentNode.querySelector(".calculation__zal-closed").style.display = "block"
                                        room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "0"
                                    }

                                }
                                // Показывать надпись, что нужно выбрать другое количество гостей
                                // document.getElementById("zal_empty").style.display = (zalsVisible === 0 ? "block" : "none")
                            }
                            // // //

                            // Открытие модалки о зале
                            // $(document).on('click', '.halls__el-btn, .calculation__zal-left, .bbanketModal__calendar-name', function (e) {
                            //     e.preventDefault()

                            //     $.ajax({
                            //         url: apiURL + '/order/room',
                            //         type: 'GET',
                            //         data: {room_id: $(this).attr('data-id')},
                            //         success: function (data) {
                            //             let sliderWp = $('.about-zal__slider-wrap')
                            //             sliderWp.html('')
                            //             $('#about-zal h4').text(data.name)
                            //             $('#about-zal .about-zal__content').html(data.description)
                            //             data.images.map((el) => {
                            //                 sliderWp.append(`
                            //                     <a class="about-zal__slider-el" href=${el} data-fancybox="gallery_banket">
                            //                         <img src=${el} alt="">
                            //                     </a>
                            //                 `)
                            //             })

                            //             aboutZal.openModal()
                            //             setTimeout(function () {
                            //                 aboutZalSlider.swiperVariable.update();
                            //             }, 50);
                            //         }
                            //     })

                            // })

                            // Установка большенства значений из куки
                            let setAllVals = () => {
                                document.querySelector('.modal-overlay').style.display = "none"
                                document.querySelector('.bbanketModal__double').style.display = "none"
                                document.querySelector('.bbanketModal__calc').style.display = "block"
                                // $('.bbanketModal__calc-grid').show()
                                document.getElementById('bbanketModal').setAttribute('data-order', getCookieBbanket("regOrderCode"))
                                orderCodeBanket = getCookieBbanket("regOrderCode")
                                // Установка гостей в финальном селекте
                                let guestCount = Number(getCookieBbanket("selectedBanketGuests"))
                                document.querySelector('.bbanketModal__calendar-select .guests').innerText = guestCount
                                document.querySelector('.bbanketModal__calendar-nums .guests').innerText = guestCount
                                document.getElementById('guest_count1').value = guestCount
                                // Скрыьт\показать подходящие залы
                                document.querySelectorAll('.bbanketModal__calc-grid label input').forEach((room) => {
                                    if (parseInt(room.getAttribute('data-min')) <= guestCount && parseInt(room.getAttribute('data-max')) >= guestCount) {
                                        room.parentNode.removeAttribute("style")
                                        room.parentNode.parentNode.querySelector(".calculation__zal-closed").style.display = "none"
                                        room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "-1"
                                    } else {

                                        room.parentNode.style.display = "none"
                                        room.parentNode.parentNode.querySelector(".calculation__zal-closed").style.display = "block"
                                        room.parentNode.parentNode.parentNode.parentNode.parentNode.style.order = "0"
                                    }
                                })
                                // Выбор даты
                                let date = new Date(getCookieBbanket("selectedBanketDate").replace('-','/').replace('-','/'))
                                registerDatapicker.selectDate(date)
                            }

                            // Отерытие основной модалки
                            document.getElementById("bbanketIcon").addEventListener("click", function (e) {
                                // В зависимости от наличия класс обнулить модалку или нет (нет, если регистрация окончена)
                                if (getCookieBbanket("saveRegister") == "registred") {
                                    document.querySelector('.modal-overlay').style.display = "none"
                                }

                                // Metrika Когда идет открытие 1 экрана виджета
                                if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
                                    ym(bbanketMetrikaIdInc,'reachGoal','vidgetauth')
                                    metrikaDopInfoFcn()
                                }

                                if (getCookieBbanket("currentStep")) {
                                    switch (getCookieBbanket("currentStep")) {
                                        case "2":
                                            document.querySelector('.modal-overlay').style.display = "block"
                                            document.querySelector('.bbanketModal__form_first').style.display = "none"
                                            document.querySelector('.bbanketModal__conditions').style.display = "none"
                                            document.querySelector('.bbanketModal__form_second').style.display = "block"
                                            document.getElementById("bbanketModal").setAttribute('data-order', getCookieBbanket("regOrderCode"))
                                            break;
                                        case "3":
                                            setAllVals()
                                            break;

                                        case "4":
                                            setAllVals()
                                            let roomId = getCookieBbanket("selectedBanketZal")
                                            document.querySelectorAll(".bbanketModal__calc-grid label input").forEach((zal) => {
                                                if (zal.value == roomId) zal.click()
                                            })
                                            // document.querySelector('.bbanketModal__calc-grid label input[value=' + roomId + ']').click()
                                            document.querySelector('.bbanketModal__calc-room').classList.add('selected')
                                            break;

                                        default:
                                            break;
                                    }
                                    if (getCookieBbanket("saveRegister") == "registred") {
                                        document.querySelector('.btn_pink').setAttribute("disabled", "disabled")
                                    }
                                } else {
                                    document.querySelector('.modal-overlay').style.display = "block"
                                }
                                // Открыть модалку
                                bbanketModal.openModal()
                            })

                            LookRoomInfo = function  (e) {
                                e.preventDefault()

                                roomCheckReq = new XMLHttpRequest()
                                roomCheckReq.open("GET", apiURL + '/order/room?room_id=' + this.getAttribute('data-id'))
                                roomCheckReq.send()
                                roomCheckReq.onload = () => {
                                    const data = JSON.parse(roomCheckReq.response)

                                    let sliderWp = document.querySelector('.about-zal__slider-wrap')
                                    sliderWp.innerHTML = ''
                                    document.querySelector('#about-zal h4').innerText = data.name
                                    document.querySelector('#about-zal .about-zal__content').innerHTML = data.description
                                    data.images.map((el) => {
                                        let slide = document.createElement("a")

                                        slide.innerHTML = `<img src=${el} alt="">`
                                        slide.setAttribute("href", el)
                                        slide.setAttribute("data-fancybox", "gallery_banket")
                                        slide.classList.add("about-zal__slider-el")
                                        slide.classList.add("swiper-slide")

                                        sliderWp.append(slide)
                                    })

                                    aboutZal.openModal()
                                    setTimeout(function () {
                                        initCustomSwiper(aboutZalSlider);
                                        aboutZalSlider.swiperVariable.update();
                                    }, 50);
                                }
                            }

                            document.querySelector('.bbanketModal__calendar-name').addEventListener("click", LookRoomInfo)

                            // Свайпер в модалке о зале
                            var aboutZalSlider = {
                                width: Infinity,
                                swiperContainer: '.about-zal__slider',
                                swiperContainerWrap: '.about-zal__slider .about-zal__slider-wrap',
                                swiperContainerSlide: '.about-zal__slider .about-zal__slider-el',
                                swiperVariable: undefined,
                                swiperUpdateTime: undefined,
                                swiperSettings: {
                                    slidesPerView: 'auto',
                                    speed: 500,
                                    watchOverflow: true,
                                    freeMode: true,
                                    loop: false,
                                    navigation: {
                                        nextEl: '.about-zal__slider-nav .about-zal__slider-next',
                                        prevEl: '.about-zal__slider-nav .about-zal__slider-prev',
                                    },
                                },
                            }

                            initCustomSwiper(aboutZalSlider);
                            window.addEventListener('resize', function () {
                                initCustomSwiper(aboutZalSlider);
                            });
                            // // //

                            // Свайпер в ервом окне
                            var interfaceSlider = {
                                width: Infinity,
                                swiperContainer: '.bbanketModal__video-wp',
                                swiperContainerWrap: '.bbanketModal__video-wrap',
                                swiperContainerSlide: '.bbanketModal__video-wp img',
                                swiperVariable: undefined,
                                swiperUpdateTime: undefined,
                                swiperSettings: {
                                    slidesPerView: 'auto',
                                    speed: 500,
                                    watchOverflow: true,
                                    autoheight: false,
                                    loop: true,
                                    autoplay: {
                                        delay: 4000,
                                        disableOnInteraction: false,
                                    },
                                    on: {
                                        slideChange: function () {
                                            // Переключение в галерее
                                            document.querySelectorAll('.bbanketModal__video-prev button').forEach((button) => {
                                                if (button.dataset.index == this.realIndex) {
                                                    button.classList.add('currentSlide')
                                                } else {
                                                    button.classList.remove('currentSlide')
                                                }
                                            })
                                        },
                                    },
                                },
                            }

                            initCustomSwiper(interfaceSlider);
                            window.addEventListener('resize', function () {
                                initCustomSwiper(interfaceSlider);
                            });

                            // Переключение слайдера по клику на галерею
                            document.querySelectorAll('.bbanketModal__video-prev button').forEach((btn) => {
                                btn.addEventListener("click", function (e) {
                                    e.preventDefault()
                                    interfaceSlider.swiperVariable.slideTo(this.getAttribute('data-index'))
                                    document.querySelectorAll('.bbanketModal__video-prev button').forEach((button) => button.classList.remove('currentSlide'))
                                    this.classList.add('currentSlide')
                                })
                            })
                            // // //

                            // simple-select1 //////////////////////////////////
                            function SimpleSelectClick (e) {
                                let selectBlock = this.parentNode
                                document.querySelectorAll(".simple-select1").forEach((select) => {
                                    if (select !== selectBlock) {
                                        select.classList.remove("is-active")
                                    }
                                })
                                selectBlock.classList.toggle('is-active');
                            };

                            document.querySelectorAll('.simple-select1 .simple-select1__main').forEach((ell) => ell.addEventListener("click", SimpleSelectClick))

                            function SimpleSelectClickButton (e) {
                                let val = this.dataset.value;
                                let select = this.parentNode.parentNode.parentNode.parentNode;
                                let text = this.innerText;

                                select.classList.remove('is-active');
                                select.querySelector('.simple-select1__item').classList.remove('is-active');
                                select.querySelector('.simple-select1__selected').innerText = text;
                                select.querySelector('input').value = val;
                                this.classList.add('is-active');
                            }

                            document.addEventListener('click', function (e) {
                                if (!e.target.classList.contains('simple-select1') && !e.target.classList.contains('simple-select1__main') && !e.target.classList.contains('simple-select1__selected')) {
                                    document.querySelectorAll('.simple-select1').forEach((ss) => ss.classList.remove('is-active'));
                                }
                            });
                            ///////////////////////////////////////////////////////////
                    }

                    // добавление\удаление плейсолдера и всегда 7ка в начале
                    function focusInputPhone () {
                        if (this.value.length > 0) {
                            this.setAttribute('placeholder', '')
                        } else {
                            this.setAttribute('placeholder', '+7 (923) 111-11-11');
                        }

                        // Чтоб всегда была 7ка в начале
                        if (this.value[1] != 7) {
                            this.value = this.value.replace(this.value[1], 7);
                        }
                        this.parentNode.classList.remove('wrong')
                    }

                    document.querySelector(".phone-mask").addEventListener('focus', focusInputPhone);
                    document.querySelector(".phone-mask").addEventListener('keyup', focusInputPhone);
                    document.querySelector(".phone-mask").addEventListener("focusout", function (e) {
                        this.setAttribute('placeholder', '')
                    });
                }
                xhr.onerror = function() { console.log('onerror '+this.status); }
                // отсылаем запрос
                xhr.send();
            } catch(e) {
                console.log(e);
            }

            if (viewType !== 1) {
               setInterval(function () {
                    document.querySelector('.bbanketIcon').classList.toggle('bbanketIcon_visible')
                    setTimeout(()=> {
                        document.querySelector('.bbanketIcon').classList.toggle('bbanketIcon_visible')
                    },3000)
                }, 10000)
            }

        }
        // если на странице не существует HTML элемента с указаным идентификатором
        // выводим сообщение: блок с идентификатором id="id" отсутствует
        else { console.log('The specified block id="'+id+'" is missing'); }
    },
    // метод подключения стилей виджета
    addStyle: function() {
        style = document.createElement('link');
        style.rel = 'stylesheet';
        style.type = 'text/css';
        style.href = this.url_style;
        document.head.appendChild(style);
    },
    // addLibs: function() {
    //     style = document.createElement('link');
    //     style.rel = 'stylesheet';
    //     style.type = 'text/css';
    //     style.href = this.url_libs;
    //     document.head.appendChild(style);
    // },
};

// Инифиализация свайреоа
function initCustomSwiper(settings) {
    var scrollWidth = scrollbarWidth();
    var screenWidth = document.documentElement.clientWidth;
    if ((screenWidth <= (settings.width - scrollWidth)) && (settings.swiperVariable == undefined)) {
        document.querySelector(settings.swiperContainer).classList.add('swiper-container');
        if (settings.swiperContainerWrap === undefined) {
            document.querySelector(settings.swiperContainerSlide).innerHTML = `<div class='swiper-wrapper'>${document.querySelector(settings.swiperContainerSlide).innerHTML}</div>`
        } else {
            document.querySelector(settings.swiperContainerWrap).classList.add('swiper-wrapper');
        }
        document.querySelectorAll(settings.swiperContainerSlide).forEach((slide) => slide.classList.add('swiper-slide'));
        settings.swiperVariable = new Swiper(settings.swiperContainer, settings.swiperSettings);

        if (settings.swiperUpdateTime != undefined) {
            window.addEventListener('load', function () {
                setTimeout(function () {
                    settings.swiperVariable.update();
                }, 200);
            });
        }
    } else if ((screenWidth > settings.width - scrollWidth) && (settings.swiperVariable != undefined)) {
        settings.swiperVariable.destroy();
        settings.swiperVariable = undefined;
        document.querySelector(settings.swiperContainer).classList.remove('swiper-container');
        if (settings.swiperContainerWrap === undefined) {
            document.querySelector(settings.swiperContainerSlide).innerHTML = document.querySelector(settings.swiperContainerSlide).querySelector("swiper-wrapper").innerHTML
        } else {
            document.querySelector(settings.swiperContainerWrap).classList.remove('swiper-wrapper');
        }
        document.querySelectorAll(settings.swiperContainerSlide).forEach((slide) => slide.classList.remove('swiper-slide'));
    }
}

function scrollbarWidth() {
    var documentWidth = parseInt(document.documentElement.clientWidth);
    var windowsWidth = parseInt(window.innerWidth);
    var scrollbarWidth = windowsWidth - documentWidth;
    return scrollbarWidth;
}
// //


// Маски для телефона\кода
function phone_mask() {
    let phoneEl = document.querySelector('.phone-mask'),
        codeEl = document.querySelector(".code-mask")
    IMask(phoneEl, {mask: '+{7} (000) 000-00-00'});
    IMask(codeEl, {
        mask: '0000',
        lazy: false,
        placeholderChar: '_'
    });
}

// $(document).on('click', '.bbanketModal__back', function (e) {
//     $('#bbanketModal').fadeOut()
// })


function getCookieBbanket(name) {
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : undefined;
}

function setCookieBbanket(name, value, options = {}) {

  options = {
    path: '/',
    // при необходимости добавьте другие значения по умолчанию
    ...options
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += "; " + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += "=" + optionValue;
    }
  }

  document.cookie = updatedCookie;
}

function deleteCookieBbanket(name) {
  setCookieBbanket(name, "", {
    'max-age': -1
  })
}







