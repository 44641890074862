let registerDatapicker
let mainDatapicker

function checkDateAjax (dataAjax) {
    let dataCheck = new XMLHttpRequest()

    // Metrika Пользователь выбрал дату (нажатие на дату в календаре виджета)
    if (typeof ym !== "undefined" && bbanketMetrikaIdInc) {
        ym(bbanketMetrikaIdInc,'reachGoal','vidgetchoosedate')
        metrikaDopInfoFcn()
    }

    dataCheck.open("GET", `${apiURL}/widget/option/calculate?room_id=${dataAjax.room_id}&guest_count=${dataAjax.guest_count}&date=${dataAjax.date}&order_code=${dataAjax.order_code}`)
    dataCheck.setRequestHeader("Accept", `application/json`)
    dataCheck.setRequestHeader("Content-Type", `application/json`)
    dataCheck.send(JSON.stringify(dataAjax))
    dataCheck.onload = () => {

        if (dataCheck.status !== 200) {
            document.querySelector(".bbanketModal__calendar-price").style.display = "none"
            document.querySelector(".bbanketModal__calendar-content").style.display = "none"
            document.getElementById("btn_broned_mob").classList.remove("blocked")
            prevReserve = true
        } else {
            document.querySelector(".bbanketModal__calendar-price").style.display = "block"
            document.querySelector(".bbanketModal__calendar-content").style.display = "block"

        }

        if (dataCheck?.response && dataCheck?.response !== "pack not found" && !!dataCheck?.response) {
            const data = JSON.parse(dataCheck?.response ?? "{}")

            document.querySelector(".bbanketModal__calendar-sum").innerText = `${data.pack_type == 0 ? "от " : ""} ${divideNumberByPieces(data.cost)} руб`
            let contentBlock = document.querySelector(".bbanketModal__calendar-content")
            contentBlock.innerHTML = ""

            prevReserve = !!data.prev_reserve

            if (prevReserve) {
                document.getElementById("btn_broned_mob").classList.remove("blocked")
            } else {
                document.getElementById("btn_broned_mob").classList.add("blocked")
            }

            const guestCount = data.prev_guest_count%10
            if (data.prev_guest_count) {
                document.querySelector(".you_broned_guests").innerText = data.prev_guest_count + ` ${guestCount === 1 ? "гость" : guestCount > 1 && guestCount < 5 ? "гостя": "гостей"}`
            } else {
                document.querySelector(".you_broned_guests").innerText = ""
            }

            // Еда\напитки
            let foodList = []

            for (const key in data.food_amount) {
                let foodObj = data.food_amount[key]

                if (key === "1") {
                        for (const keyy in foodObj) {
                        let foodEl = foodObj[keyy]

                        let resultWeight = separateSummWeightFcn(foodEl.amount_by_person)

                        foodList.push(`
                            <li>
                                ${foodEl.name}: ${resultWeight}
                            </li>`
                        )
                    }
                } else {
                    for (const keyy in foodObj) {
                        let foodEl = foodObj[keyy]
                        foodList.push(`
                            <li>
                                ${foodEl.name}: ${Math.round10(foodEl.amount_by_person / 1000, -1)} л
                            </li>`
                        )
                    }
                }
            }

            //
            let serviceList = []
            for (const key in data.services) {
                let service = data.services[key]
                serviceList.push(`
                    <li>
                        ${service.name}: ${service.cost} руб
                    </li>
                `)
            }
            // Не обязательные услуги
            let serviceListNotMand = []
            for (const key in data.not_mandatory_services) {
                let service = data.not_mandatory_services[key]
                serviceListNotMand.push(`
                    <li>
                        ${service.name}: стоимость по запросу
                    </li>
                `)
            }

            bbanketWidgetCode = data.code
            bbanketWidgetLink = data.link

            // Ссылка на расчёт
            // let optionLink = document.getElementsByClassName("bbanketModal__calendar-link")[0]
            if (data.link) {
                // optionLink.style.display = "block"
                document.getElementsByClassName("bbanketModal__calendar-option")[0].setAttribute("href", data.link)
                // document.getElementById("btn_broned_mob").innerText = "Забронировать дату и условия"
            } else {
                // optionLink.style.display = "none"
                // document.getElementById("btn_broned_mob").innerText = "Узнать детали или Забронировать"
            }

            contentBlock.innerHTML = `
                <p>Что входит в стоимость?</p>
                <li>
                    Еда и напитки: ${data.food_cost} руб
                </li>
                ${serviceList.join('')}
                <p>Еды на гостя: ${separateSummWeightFcn(data.amount)}</p>
                ${foodList.join('')}
                ${serviceListNotMand.length !== 0 ?
                    `<p>Дополнительные услуги:</p>
                    ${serviceListNotMand.join('')}`
                    : ""
                }
            `

        } else {
            document.querySelector(".bbanketModal__calendar-content").innerHTML = dataCheck?.response
        }
    }
}

function separateSummWeightFcn (weight) {
    var separateSummWeight = separateUnits(weight),
        // Сумма всех грамм и кг на человека
        summGramm = 0,
        summKg = 0,
        itemWeight = "";

    // В зависимости от длинны массива выделяем разряды числа (веса)
    switch (separateSummWeight.length) {
        case 1:
            summGramm = separateSummWeight[0];
            itemWeight = summGramm + " гр"
            break;
        case 2:
            summGramm = separateSummWeight[1] + separateSummWeight[0];
            itemWeight = summGramm + " гр"
            break;
        case 3:
            summGramm =
                separateSummWeight[0] +
                separateSummWeight[1] +
                separateSummWeight[2];
                itemWeight = summGramm + " гр"
            break;
        case 4:
            summGramm =
                separateSummWeight[1] +
                separateSummWeight[2] +
                separateSummWeight[3];
            summKg = separateSummWeight[0];
            itemWeight = summKg / 1000 + " кг "
            if (summGramm != 0 && !isNaN(summGramm)) {
                itemWeight = itemWeight + summGramm + " гр"
            }
            break;
        case 5:
            summGramm =
                separateSummWeight[2] +
                separateSummWeight[3] +
                separateSummWeight[4];
            summKg = separateSummWeight[0] + separateSummWeight[1];
            itemWeight = summKg / 1000 + " кг "
            if (summGramm != 0 && !isNaN(summGramm)) {
                itemWeight = itemWeight + summGramm + " гр"
            }
            break;
        case 6:
            summGramm =
                separateSummWeight[3] +
                separateSummWeight[4] +
                separateSummWeight[5];
            summKg =
                separateSummWeight[0] +
                separateSummWeight[1] +
                separateSummWeight[2];
            itemWeight = summKg / 1000 + " кг "


            if (summGramm != 0 && !isNaN(summGramm)) {
                itemWeight = itemWeight + summGramm + " гр"
            }
            break;
    }

    return itemWeight
}

