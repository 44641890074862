// $(function () {

// });

// _scrollIntoView: function( item ) {
//     var borderTop, paddingTop, offset, scroll, elementHeight, itemHeight;
//     if ( this._hasScroll() ) {
//         borderTop = parseFloat( $.css( this.activeMenu[ 0 ], "borderTopWidth" ) ) || 0;
//         paddingTop = parseFloat( $.css( this.activeMenu[ 0 ], "paddingTop" ) ) || 0;
//         offset = item.offset().top - this.activeMenu.offset().top - borderTop - paddingTop;
//         scroll = this.activeMenu.scrollTop();
//         elementHeight = this.activeMenu.height();
//         itemHeight = item.outerHeight();
//
//         if ( offset < 0 ) {
//             this.activeMenu.scrollTop( scroll + offset );
//         } else if ( offset + itemHeight > elementHeight ) {
//             this.activeMenu.scrollTop( scroll + offset - elementHeight + itemHeight );
//         }
//     }
// }

function resetSelect1(select) {
    document.querySelectorAll(select).forEach(function (el) {
        let pb = el;

        let defaultValue = pb.querySelector('input').dataset['default-value'];
        let placeholder = pb.querySelector('.simple-select1__selected').dataset.placeholder;

        if (defaultValue && !placeholder) {
            let item
            pb.querySelectorAll(`.simple-select1__item`).forEach((btn) => {
                if (btn.dataset.value == defaultValue) item = btn
            });

            pb.querySelector('input').value = defaultValue;
            pb.querySelector('.simple-select1__selected').textContent = item.textContent;
            pb.querySelectorAll('.simple-select1__item').forEach((item) => {
                item.classList.remove('is-active')
            })
            item?.classList.add('is-active');
        } else {
            pb.querySelector('input').value = '';
            pb.querySelector('.simple-select1__selected').textContent = '';
            pb.querySelectorAll('.simple-select1__item').forEach((item) => {
                item.classList.remove('is-active')
            })
        }
    });
}
